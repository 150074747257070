<template>
  <div>
    <!--TITULOS  -->
    <div class="air__utils__heading"> <h5>Detale de Reactivo</h5> </div>
    <my-breadcrumbs :routes="routes"/>
    <!--TITULOS  -->
    <!--DETALLE DE REACTIVO  -->
    <div class="row my-4">
      <b-container fluid>
        <a-card :title="`${reagent.code} - ${reagent.name}`">
          <a-card-grid style="width:50%;text-align:center" :hoverable="false">
            <a-card-meta>
              <template slot="description">
                <strong>Existencia mínima:</strong> <span>{{ reagent.min }}</span>
                <br class="bg-primary">
                <strong>Existencia máxima:</strong> <span>{{ reagent.max }}</span>
                <br class="bg-primary">
                <strong>Especificación técnica:</strong>
                <br>
                <small>{{ reagent.technical_specification }}</small>
              </template>
            </a-card-meta>
          </a-card-grid>
          <a-card-grid style="width:50%;text-align:center" :hoverable="false">
            <a-card-meta>
              <template slot="description">
                <strong>Piezas disponibles:</strong> <span class="text-primary">TEST</span>
                <br class="bg-primary">
                <strong>Debajo del Mínimo:</strong> <span class="text-danger">TEST</span>
                <br class="bg-primary">
                <strong>Piezas requeridas :</strong> <span class="text-warning">TEST</span>
              </template>
            </a-card-meta>
          </a-card-grid>
        </a-card>
      </b-container>
    </div>
    <!--DETALLE DE REACTIVO  -->
    <!--BOTONERA  -->
    <div class="row justify-content-end mr-2">
      <b-button-group>
        <b-button  variant="primary" @click="OpenModal()" pill>
          <div class="d-none d-md-block" v-b-tooltip.hover title="Nuevo">
            <b-icon icon="plus"/> Nuevo
          </div>
          <div class="d-md-none" v-b-tooltip.hover title="Nuevo">
            <b-icon icon="plus"/>
          </div>
        </b-button>
      </b-button-group>
    </div>
    <!--BOTONERA  -->
    <!--BARRA DE BUSQUEDA  -->
    <searchingBar :components="['filter', 'sort', 'pages']"
          :sortByOptions="sortByOptions"
          @Fectch="FetchTable"
          @filterValue="filterValue = $event"
          @sortValue="tableObject.sortValue = $event"
          @pageSize="tableObject.pagination.pageSize = $event"
          @currentPageValue="tableObject.pagination.current = $event"/>
    <!--BARRA DE BUSQUEDA  -->
    <!--TABLA-->
    <a-table bordered
       :scroll="{x:1300}"
        :columns="tableObject.columns"
        :data-source="tableObject.data"
        :pagination="tableObject.pagination"
        :loading="tableObject.loading"
        @change="FetchTable"
        :row-key="record => record.id">
      <template slot="id" slot-scope="data">
        <strong>{{ data.code }} - {{data.serial_number}}</strong>
      </template>
      <template slot="details" slot-scope="data">
        <a-descriptions :title="`${data.code}`"   size="small"
                      :column="{ sm: 1, md: 2, lg: 2, xl: 2}" bordered="">
            <a-descriptions-item label="Marca">
              <strong>{{ data.reagentBrands.name }}</strong>
            </a-descriptions-item>
          <a-descriptions-item label="Cantidad del contenedor">
            <strong>{{ data.container_quantity }} - {{data.ums.name}}</strong>
          </a-descriptions-item>
          <a-descriptions-item label="Fecha de entrada">
            <strong>{{ moment(data.entry_dated_at).format('DD/MM/YYYY')}}</strong>
          </a-descriptions-item>
          <a-descriptions-item label="Fecha de caducidad">
            <strong>{{ moment(data.expiry_dated_at).format('DD/MM/YYYY')}}</strong>
          </a-descriptions-item>
        </a-descriptions>
      </template>
      <template slot="expired" slot-scope="data">
        <b-icon v-if="(moment(data.expiry_dated_at)).diff(moment(),'days') < 0" icon="x-circle-fill" font-scale="2.5" variant="danger"/>
        <b-icon v-else-if="(moment(data.expiry_dated_at)).diff(moment(),'days') > 0 && (moment(data.expiry_dated_at)).diff(moment(),'days') < 32" icon="exclamation-triangle-fill" font-scale="2.5" variant="warning"/>
        <b-icon v-else icon="check-circle-fill" font-scale="2.5" variant="primary"/>
      </template>
      <template slot="action" slot-scope="data">
        <b-button  variant="secondary" @click="OpenModal(data)" pill>
          <div class="d-none d-md-block" v-b-tooltip.hover title="Editar">
            <b-icon icon="pencil-square"/> Editar
          </div>
          <div class="d-md-none" v-b-tooltip.hover title="Editar">
            <b-icon icon="pencil-square"/>
          </div>
        </b-button>
        <b-button  variant="danger" @click="Discard(data)" pill>
            <div class="d-none d-md-block" v-b-tooltip.hover title="Dar de baja">
                <b-icon icon="x"/> Baja
            </div>
            <div class="d-md-none" v-b-tooltip.hover title="Dar de baja">
                <b-icon icon="x"/>
            </div>
        </b-button>
      </template>
    </a-table>
    <!--TABLA-->
    <!-- MODAL -->
    <b-modal title="Detalle de Detale de Reactivo" ref="modalForm" size="lg" @hide="CloseModal" >
      <b-container fluid>
        <!--FORMULARIO-->
        <a-form-model :model="resourceObj.form" :rules="formRules" ref="antForm"
                      layout="vertical" :wrapper-col="{ span: 24}" v-show="!spinnerLoad">
        </a-form-model>
        <my-spinner :load="spinnerLoad"/>
        <!--FORMULARIO-->
      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- MODAL -->
  </div>
</template>
<script>
import { fractalMixin } from '@/mixins/fractalMixin'
export default {
  name: 'reagentAuxiliars',
  mixins: [fractalMixin],
  data() {
    return {
      // Constantes
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'laboratories',
          breadcrumbName: 'Laboratorio',
        },
        {
          breadcrumbName: 'Almacén de Laboratorio',
          name: 'warehouseMenu',
        },
        {
          breadcrumbName: 'Almacén de Reactivos',
          name: 'reagentsResume',
        },
        {
          breadcrumbName: 'Detale de Reactivo',
        },
      ],
      // Constantes
      // SearchBar
      sortByOptions: [
        { value: '', text: 'Ordenar por', disabled: true },
      ],
      filterValue: '',
      // SearchBar
      // Tabla
      tableObject: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          // code
          // numeric
          // serial_number
          // cost
          // container_quantity
          // entry_dated_at
          // expiry_dated_at
          // reagents
          // workCenters
          // reagentBrands
          // ums
          {
            title: 'Clave/No.Serie',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'id' },
            // width: '20%',
          },
          {
            title: 'Costo (M.N)',
            dataIndex: 'cost',
            class: 'small text-center',
            width: '100px',
          },
          {
            title: 'Detalles',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'details' },
            width: '600px',
          },
          {
            title: 'Caducidad',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'expired' },
            width: '100px',
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'small text-center',
            scopedSlots: { customRender: 'action' },
            // fixed: 'right',
            width: '200px',
          },
        ],
      },
      // Tabla
      // Objeto principal del recurso
      resourceObj: {
        resourceType: 'reagentAuxiliars',
        form: {
          id: '',
        },
        relationships: [],
      },
      // Reglas de Formulario
      formRules: {
        field: [{ required: true, message: 'El campo __ es obligatorio.', trigger: 'blur' }],
      },
      // Combos de Formulario
      // Variables para el flujo del formulario
      spinnerLoad: false,
      reagentId: this.$route.params.reagentId,
      reagent: {
        code: '',
        name: '',
        families: {},
      },
    }
  },
  methods: {
    async FetchReagent() {
      this.reagent = await this.GetResource(`/reagents/${this.reagentId}`, {})
    },
    // CRUD
    async FetchTable(pagEvent = {}) {
      const params = {
        'page[number]': `${this.tableObject.pagination.current}`,
        'page[size]': `${this.tableObject.pagination.pageSize}`,
        include: 'ums,reagentBrands',
        'filter[reagents]': this.reagentId,
      }
      await this.GetTableResource(pagEvent, this.resourceObj.resourceType, this.tableObject, params)
    },
    async Submit() {
      this.$refs.antForm.validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          if (this.resourceObj.form.id === '') {
            await this.PostResource(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
          } else await this.PutResource(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
        } else return false
      })
    },
    async AskDelete() {
      await this.deleteQuestion(this.resourceObj.resourceType, this.resourceObj.form.id, this.SuccessCallback)
    },
    async Fetch() {},
    OpenModal(data = {}) {
      this.$refs.modalForm.show()
      if (Object.keys(data).length === 0) {
        //  Store case
        this.ResetForm()
      } else {
        // Update case
        this.resourceObj.form = {
          id: data.id.toString(),
        }
      }
    },
    CloseModal() {
      this.$refs.modalForm.hide()
      this.ResetForm()
    },
    ResetForm() {
      this.resourceObj.form = {}
    },
    async SuccessCallback() {
      this.spinnerLoad = false
      await this.FetchTable()
      this.CloseModal()
    },
    // CRUD
  },
  async mounted() {
    // console.log(this.$route.params.reagentId)
    await this.FetchReagent()
    await this.FetchTable()
  },
}
</script>
<style scoped></style>
